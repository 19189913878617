export default {
    title: 'Photography',
    copy: {
        span1: '%(link)s',
        span2: '%(weddings)s',
        span3: '%(fauna)s',
        span4: '%(travels)s',
        span5: '%(portraits)s',
        span7: '%(events)s',
        span8: '%(food)s', 
        span9: '%(about)s',
        span10: '%(contact)s'
    },
    link: 'Portfolio',
    weddings: 'Weddings',
    nature: 'Nature',
    animals: 'Animals',
    travels: 'Travels',
    portraits: 'Portraits',
    events: 'Events',
    food: 'Food',
    about: 'About',
    contact: 'Contact & pricing',
    tabs: {
        africa: 'Africa',
        asia: 'Asia',
        europe: 'Europe', 
        casual: 'casual',
        corporate: 'Corporate',
        corporatevents: 'Corporate',
        social: 'Social'
    },
    textp1: 'I am French and I arrived in the Netherlands in 2007 looking for a new adventure.',
    textp2: 'I was 20 and I could barely speak English. I lived several years in Amsterdam and I am now located in the beautiful city of Utrecht, in the heart of the country.',
    textp3: 'Self-taught photographer and passionate since my teenage years, my camera is always at hand during my long walks or when I travel around the world with my wife.',
    textp4: 'I particularly appreciate wedding photography which also means joyful moments and love. However, being very open-minded, I do not impose myself any limits.',
    textp5: 'I like to photograph everything that captures my attention: portraits, landscapes, street and travel photography, animal or even astronomy.',
    textp6: 'During my career as a wedding photographer, I had the chance to combine business with pleasure by working in Czech Republic, Slovakia, Belgium, Spain, Switzerland and of course in France and in the Netherlands.',
    textp7: 'By browsing this site, you will notice the importance I pay to small details as well as the particular attention I give in order to achieve what I consider to be the perfect shot.',
    textp8: 'Indeed, I am always looking for the optimal balance between composition, colors and lights while letting emotions, an essential element for a successful photo, shine through.',
    textp9: 'I warmly invite you to contact me for any information on my services and prices.',
    textcontact: 'Follow me',
    alban: `Alban Wagner Photography - © ${new Date().getFullYear()}`,
    text10: 'Do you need more information, would you like to make a booking or simply to get in touch?',
    text11: 'Please contact me at +31 6 40 79 57 25 or send me an message via the below form.',
    text12: 'You can also send an email to info@albanwagner.com.  I will revert back as soon as possible.',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    send: 'Send'
}