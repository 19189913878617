export default {
    title: 'Photographie',
    copy: {
        span1: '%(link)s',
        span2: '%(weddings)s',
        span3: '%(fauna)s',
        span4: '%(travels)s',
        span5: '%(portraits)s',
        span7: '%(events)s',
        span8: '%(food)s', 
        span9: '%(about)s',
        span10: '%(contact)s'
    },
    link: 'Portefeuille',
    weddings: 'Mariages',
    nature: 'Nature',
    animals: 'Animaux',
    travels: 'Voyages',
    portraits: 'Portraits',
    events: 'Evenements',
    food: 'Culinaire',
    about: 'À propos',
    contact: 'Contact & pricing',
    tabs: {
        africa: 'Afrique',
        asia: 'Asie',
        europe: 'Europe',
        casual: 'Décontracté',
        corporate: 'Entreprise',
        corporatevents: `Entreprise`,
        social: 'Sociaux'
    },
    textp1: 'Je suis français, arrivé aux Pays-Bas en 2007 à la recherche d’une nouvelle aventure. ',
    textp2: 'J’avais 20 ans et je pouvais à peine parler anglais tant bien que mal. J’ai vécu plusieurs années à Amsterdam et je réside désormais dans la belle ville d’Utrecht, au coeur du pays.',
    textp3: `Photographe autodidacte et passionné depuis mon adolescence, mon appareil n'est jamais loin lors de mes longues promenades ou lorsque je voyage à travers le monde avec ma femme.`,
    textp4: `J'apprécie particulièrement la photographie de mariage car elle est synonyme de joie et d'amour. Cependant étant très ouvert d'esprit je ne m'impose aucune limite.`,
    textp5: `J'aime photographier tout ce qui suscite mon attention: portraits, paysages, photographie de rue et de voyage, animalière ou encore d'astronomie.`,
    textp6: `Au cours de ma carrière de photographe de mariage, j'ai eu l'opportunité de joindre l'utile et l'agréable en offrant mes services en République Tchèque, en Slovaquie, en Belgique, en Espagne, en Suisse et bien entendue en France et aux Pays-Bas.`,
    textp7: `En parcourant ce site, vous remarquerez l'importance que je porte aux petits détails et à l'attention particulière apportée afin de réaliser ce que je considère être la photographie parfaite.`,
    textp8: `En effet, je suis toujours à la recherche de la combinaison optimale entre compositions, couleurs et lumières tout en laissant transparaître au mieux les émotions, élément essentiel pour une photo réussie.`,
    textp9: `Je vous invite chaleureusement à me contacter pour tout renseignement sur mes services et tarifs.`,
    textcontact: 'Suis Moi',
    alban: 'Alban Wagner Photographie - © 2021',
    text10: 'Vous désirez plus d’informations, prendre rendez-vous ou tout simplement papoter?',
    text11: 'Vous pouvez me contacter au +31 6 40 79 57 25 ou bien remplir le formulaire ci-dessous.',
    text12: 'Vous pouvez aussi m’envoyer un email à info@albanwagner.com. Je vous répondrai dès que possible.',
    name:'Nom',
    email:'Email',
    message: 'Message',
    send: 'Envoyer'
}
